import React, { createContext, useState } from 'react'

export const MyContext = createContext()

export const CryptoKeyProvider = ({ children }) => {
  const [valueOfGroup, setValueOfGroup] = useState()
  const [showGroupPopUp, setShowGroupPopUp] = useState(false)
  const [numberGroup, setNumberGroup] = useState()

  return (
    <MyContext.Provider
      value={{
        valueOfGroup,
        setValueOfGroup,
        showGroupPopUp,
        setShowGroupPopUp,
        numberGroup,
        setNumberGroup,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

export default MyContext
