import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import { EffectCoverflow, Navigation } from 'swiper/modules'
import {
  ph1,
  ph2,
  ph3,
  ph4,
  ph5,
} from '../../../Assets/Images/MyImages/MyImages'
import './mycarousel.css'

const MyCarousele = () => {
  const items = [ph1, ph4, ph5, ph3, ph2]

  const [index, setIndex] = useState(1)
  const totalSlides = items.length

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
    >
      <div>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          centeredSlidesBounds={true}
          autoplay={true}
          loop={true}
          slidesPerView={3}
          slidesPerGroupSkip={1}
          spaceBetween={-200}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 5,
          }}
          modules={[EffectCoverflow, Navigation]}
          // navigation={{
          //   nextEl: '.arrow-right',
          //   prevEl: '.arrow-left',
          //   clickable: true,
          // }}
          className={'swiper_container '}
          initialSlide={0}
          onSlideChange={(swiper) =>
            setIndex(
              (((swiper.realIndex % totalSlides) + totalSlides) % totalSlides) +
                1,
            )
          }
        >
          {items.map((item, i) => (
            <SwiperSlide
              key={i}
              className={i === 2 ? 'swiper-slide-active' : ''}
            >
              <img src={item} alt={`slide${i + 1}`} className={'myimg'} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/*<svg*/}
      {/*  xmlns="http://www.w3.org/2000/svg"*/}
      {/*  width="116"*/}
      {/*  height="115"*/}
      {/*  viewBox="0 0 116 115"*/}
      {/*  fill="none"*/}
      {/*  className={'arrow arrow-left'}*/}
      {/*>*/}
      {/*  <ellipse*/}
      {/*    cx="58.083"*/}
      {/*    cy="57.3176"*/}
      {/*    rx="57.4641"*/}
      {/*    ry="57.2946"*/}
      {/*    transform="rotate(-180 58.083 57.3176)"*/}
      {/*    fill="#F8F8F8"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M29.8221 55.9546C29.0694 56.7073 29.0694 57.9277 29.8221 58.6804L42.0884 70.9467C42.8411 71.6994 44.0615 71.6994 44.8142 70.9467C45.5669 70.194 45.5669 68.9736 44.8142 68.2209L33.9108 57.3175L44.8142 46.4141C45.5669 45.6614 45.5669 44.441 44.8142 43.6883C44.0615 42.9356 42.8411 42.9356 42.0884 43.6883L29.8221 55.9546ZM86.2039 55.39L31.185 55.39L31.185 59.2449L86.2039 59.245L86.2039 55.39Z"*/}
      {/*    fill="#1A92FD"*/}
      {/*  />*/}
      {/*</svg>*/}
      {/*<svg*/}
      {/*  xmlns="http://www.w3.org/2000/svg"*/}
      {/*  width="116"*/}
      {/*  height="115"*/}
      {/*  viewBox="0 0 116 115"*/}
      {/*  fill="none"*/}
      {/*  className={'arrow arrow-right'}*/}
      {/*>*/}
      {/*  <ellipse*/}
      {/*    cx="57.917"*/}
      {/*    cy="57.3173"*/}
      {/*    rx="57.4641"*/}
      {/*    ry="57.2946"*/}
      {/*    fill="#F8F8F8"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M86.1779 58.6803C86.9306 57.9276 86.9306 56.7072 86.1779 55.9545L73.9116 43.6882C73.1589 42.9355 71.9385 42.9355 71.1858 43.6882C70.4331 44.4409 70.4331 45.6613 71.1858 46.414L82.0892 57.3174L71.1858 68.2208C70.4331 68.9735 70.4331 70.1939 71.1858 70.9466C71.9385 71.6993 73.1589 71.6993 73.9116 70.9466L86.1779 58.6803ZM29.7961 59.2448L84.815 59.2449L84.815 55.3899L29.7961 55.3899L29.7961 59.2448Z"*/}
      {/*    fill="#1A92FD"*/}
      {/*  />*/}
      {/*</svg>*/}
      <div className={'numbers'}>
        <div className={'line line_left'} />
        <span className={'numbers_left'}>{index}</span>
        <div className={'numbers_line'} />
        <span className={'numbers_right'}>{totalSlides}</span>
        <div className={'line line_right'} />
      </div>
    </div>
  )
}

export default MyCarousele
