import React, { useState } from 'react'
import Marquee from 'react-fast-marquee'
import {
  ChildrenPhoto1,
  ChildrenPhoto2,
  ChildrenPhoto3,
  ChildrenPhoto4,
  ChildrenPhoto5,
} from '../../Assets/Images/images'
import ImageModal from '../../Components/ImageModal/ImageModal'
import './Children.css'
const Children = () => {
  const [selectedImage, setSelectedImage] = useState(null)

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl)
  }

  const closeImageModal = () => {
    setSelectedImage(null)
  }
  return (
    <div className="children">
      <div className="container">
        <h2 className="children-title">Діти з особливими потребами</h2>
        <p className="children-text">
          В школі раннього плавання «Здорова дитина» працює група дітей з
          особливими потребами. Тут в першу чергу індивідуальний підхід до
          кожної дитини. Заняття спрямовані на фізичний розвиток, розвиток
          дрібної моторики, а завдяки пірнанням та різним ігровим вправам
          активно розвивається розумова діяльність.
        </p>
      </div>
      <Marquee
        pauseOnHover={true}
        direction="left"
        speed={100}
        className="children-carousel"
      >
        <div
          className="children-wrapper"
          onClick={() => openImageModal(ChildrenPhoto1)}
        >
          <img src={ChildrenPhoto1} alt="" className="children-img" />
        </div>
        <div
          className="children-wrapper"
          onClick={() => openImageModal(ChildrenPhoto2)}
        >
          <img src={ChildrenPhoto2} alt="" className="children-img" />
        </div>
        <div
          className="children-wrapper"
          onClick={() => openImageModal(ChildrenPhoto3)}
        >
          <img src={ChildrenPhoto3} alt="" className="children-img" />
        </div>
        <div
          className="children-wrapper"
          onClick={() => openImageModal(ChildrenPhoto4)}
        >
          <img src={ChildrenPhoto4} alt="" className="children-img" />
        </div>
        <div
          className="children-wrapper"
          onClick={() => openImageModal(ChildrenPhoto5)}
        >
          <img src={ChildrenPhoto5} alt="" className="children-img" />
        </div>
      </Marquee>
      {selectedImage && (
        <ImageModal
          imageUrl={selectedImage}
          onClose={closeImageModal}
          className="childen-img"
        />
      )}
    </div>
  )
}

export default Children
