import React from 'react'
import { headerLogo } from '../../Assets/Icons/icons'

const HeaderNavBar = () => {
  return (
    <div className="header-navbar container">
      <div className="header-wrapper">
        <img src={headerLogo} alt="" className="header-logo" />
        <div className="header-menu">
          <a href="#trainer" className="header-link">
            Про тренера
          </a>
          <a href="#schoolLink" className="header-link">
            Про школу
          </a>
          <a href="#pediatrLink" className="header-link">
            Педіатри
          </a>
        </div>
      </div>
      <div className="header-wrapper">
        <a href="tel:+38(095) 32 40 708" className="header-phone">
          +38(095) 32 40 708
        </a>
        <a
          className={'header-button header-button__link'}
          href={'https://cabinet.zd-school.com.ua'}
        >
          Увійти
        </a>
      </div>
    </div>
  )
}

export default HeaderNavBar
