export const accordionData = [
  {
    title: 'Як підготуватись до заняття?',
    content: `
Якщо Ваша дитина вдома купається в температурі вище 37, то варто до занять в басейні цю температуру правильним чином понизити до температури 35. Тоді контраст між водичкою вдома і водичкою в басейні не буде таким відчутнім і дитині буде легше адаптуватись до занять   `,
  },
  {
    title: 'Що з собою треба мати на заняття?',
    content: `
Мамі:
 ⁃ Зручний купальник 
 ⁃ Змінне взуття
 ⁃ Шапочку 
 ⁃ Рушник 

Дитині вік від 2міс до 3 років:
 ⁃ памперс або трусики для басейну 
 ⁃ Окуляри 
 ⁃ Ласти 

Дитині від 3х років 
 ⁃ зручний купальник/ плавки 
 ⁃ Змінне взуття 
 ⁃ Шапочка 
 ⁃ Рушник 
 ⁃ Ласти 
 ⁃ Окуляри 

    `,
  },
  {
    title: 'Скільки часу триває заняття?',
    content: `
⁃ Заняття триває 30хв.
    `,
  },
  {
    title: 'Скільки часу потрібно, щоб дитина навчилась плавати?',
    content: `
Все залежить від того коли Ви почали займатись, як проходить адаптація та звичайно від Вашої стабільності в відвідуванні занять! В середньому дітки, які починають від 3х місяців відвідувати стабільно заняття, то вже в віці 1,3- 2 роки впевнено тримаються на воді! Якщо це дітки віком від 3х років то на протязі пів року стабільних занять впевнено тримаються на воді. Дітки від 5ти років починають самостійно триматись на воді вже за перші 7-10 занять.`,
  },
  {
    title: 'Скільки дітей в групі?',
    content: `
Загальна кількість діток в групі 10-ть.    `,
  },
  {
    title: 'Скільки часу потрібно дитині, щоб адаптуватись?',
    content: `
Це все дуже індивідуально проходить! Одні дітки вже з першого заняття відчувають себе в комфорті, а інші на це витрачають місяць а буває і два! Які фактори на це впливають:
- як дитина відчула воду при першому купанні
- рівень соціалізації 
- головне - мама для дитини індикатор, якщо вона хвилюється то і дитина веде себе відповідно    `,
  },
]
