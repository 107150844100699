import React from 'react'

const ButtonSignUp = ({buttonClass,ButtonText,buttonLink,buttonStyle}) => {
  return (
    <button className={buttonClass}>
      <a href={buttonLink} className={buttonStyle}>{ButtonText}</a>
    </button>
  )
}

export default ButtonSignUp
