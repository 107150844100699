import React from 'react';
import YearlyTripsItem from './YearlyTripsItem/YearlyTripsItem'
import { YearlyRainFirst, YearlyRainSecond } from '../../Assets/Images/images'


const YearlyTripsList = ({ trips }) => {
  return (
    <div className="yearlyTripsList">
      {trips.map((trip, index) => (
        <YearlyTripsItem
          key={index}
          yearlyTripsImg={trip.img}
          yearlyTripsTitle={trip.title}
          yearlyTripsText={trip.text}
        />
      ))}
      <img src={YearlyRainFirst} alt="" className='yearlytrips-raindropone'></img>
      <img src={YearlyRainSecond} alt="" className='yearlytrips-raindroptwo'></img>
    </div>
  );
};

export default YearlyTripsList;
