import React from 'react'
import { Ganuliak, Gotra, Water, Zaborovskiy } from '../../Assets/Images/images'
import styles from './doctors.module.css'
import Slider from 'react-slick'
import SampleNextArrow from './Dostors_Arrows/SampleNextArrow'
import SamplePrevArrow from './Dostors_Arrows/SamplePrevArrow'
import SampleNextArrowTablet from './Dostors_Arrows/SampleNextArrowTablet'
import SampleNextArrowMobile from './Dostors_Arrows/SampleNextArrowMobile'
import SamplePrevArrowTablet from './Dostors_Arrows/SamplePrevArrowTablet'
import SamplePrevArrowMobile from './Dostors_Arrows/SamplePrevArrowMobile'

const Doctors = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrowTablet />,
          prevArrow: <SamplePrevArrowTablet />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrowTablet />,
          prevArrow: <SamplePrevArrowTablet />,
        },
      },
      {
        breakpoint: 720,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrowMobile />,
          prevArrow: <SamplePrevArrowMobile />,
        },
      },
    ],
  }
  return (
    <div className={`${styles.doctors}  p-lg-0`} id="pediatrLink">
      <div className={styles.water_reviews}>
        <div>
          <img className={styles.water_left} src={Water} alt="water" />
        </div>
        <div className={styles.doctors_reviews}>
          <div>
            <Slider {...settings}>
              <div>
                <div className={styles.doctor_info}>
                  <div>
                    <img
                      className={styles.doctor_img}
                      src={Ganuliak}
                      alt="Ganuliak"
                    />
                  </div>
                  <div className={styles.name_post}>
                    <h3 className={styles.doctor_name}>Гануляк Оксана</h3>
                    <p className={styles.doctor_post}>
                      Сімейна лікарка, педіатр з 12 річним досвідом
                    </p>
                  </div>
                </div>
                <div>
                  <p className={styles.doctors_reviews__text}>
                    Учасник спільноти Української академії педіатричних
                    спеціальностей, щорічної конференції «Школа доказової
                    медицини» та V Конгресу УАПС “Prime Pediatrics 2023”.
                  </p>
                  <p className={styles.doctors_reviews__text}>
                    Дружнє ставлення до маленького пацієнта та лише доказова
                    інформація для батьків - це ключові правила лікаря-педіатра
                    Оксани Гануляк.
                  </p>
                </div>
              </div>
              <div>
                <div className={styles.doctor_info}>
                  <div>
                    <img
                      className={styles.doctor_img}
                      src={Zaborovskiy}
                      alt="Zaborovskiy"
                    />
                  </div>
                  <div className={styles.name_post}>
                    <h3 className={styles.doctor_name}>
                      Заборовський Олександр
                    </h3>
                    <p className={styles.doctor_post}>
                      Лікар педіатр. Керівник Bobo clinic
                    </p>
                  </div>
                </div>
                <div>
                  <p className={styles.doctors_reviews__text}>
                    Прихильник вакцинаціі і доказової медицини, основою здорової
                    дитини це сімʼя!!!
                  </p>
                  <p className={styles.doctors_reviews__text}>
                    Лікар педіатр - друг для дитини та сімʼї.
                  </p>
                  <p className={styles.doctors_reviews__text}>
                    Учасник багатьох Всеукраїнських і закордонних конференцій і
                    майстеркласів.
                  </p>
                </div>
              </div>
              <div>
                <div className={styles.doctor_info}>
                  <div>
                    <img
                      className={styles.doctor_img}
                      src={Gotra}
                      alt="Gotra"
                    />
                  </div>
                  <div className={styles.name_post}>
                    <h3 className={styles.doctor_name}>Готра Марина</h3>
                    <p className={styles.doctor_post}>Лікар педіатр</p>
                  </div>
                </div>
                <div>
                  <p className={styles.doctors_reviews__text}>
                    ФОП Кравчук С.М. (м.Виноградів, вул. Гандери, 34), лікар
                    неонатолог.
                  </p>
                  <p className={styles.doctors_reviews__text}>
                    Пріоритет в роботі: доказова медицина, імунопрофілактика.
                    Здорова дитина-щаслива родина!
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className={styles.doctors_text_block}>
        <p className={styles.doctors_text}>Нас рекомендують педіатри</p>
      </div>
    </div>
  )
}
export default Doctors
