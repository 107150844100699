import React from 'react'
import './YearlyTripsItem.css'
const YearlyTripsItem = ({yearlyTripsImg,yearlyTripsTitle,yearlyTripsText}) => {
  return (
    <div className="yearlyTripsItem">
      <img src={yearlyTripsImg} alt='' className='yearlyTripsItem-img' />
      <div className='yearlyTripsItem-wrapper'>
      <p className='yearlyTripsItem-title'>{yearlyTripsTitle}</p>
      <p className='yearlyTripsItem-text'>{yearlyTripsText}</p>
      </div>
    </div>
  )
}

export default YearlyTripsItem
