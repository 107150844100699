import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import './FormTeachers.css'
import { TeacherFileImg } from '../../Assets/Images/images'
import { chatId, validator, baseURL, fileURL } from '../../Constants/urls.js'
import Button from 'react-bootstrap/Button'

const FormTeachers = (props) => {
  let redBord = { border: '2px solid red' }
  let blackBord = { border: '1px solid #1A92FD' }
  const variants = {
    visible: {
      display: 'block',
      opacity: 1,
    },
    unVisible: {
      display: 'none',
      opacity: 0,
    },
  }
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' })
  const [formData] = useState({
    phone: true,
    work: true,
    place: true,
  })

  // FILE CHECKER
  const [file, setFile] = useState(null)
  const handleFileChange = (event) => {
    // get file from input
    const selectedFile = event.target.files[0]

    // check file size
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFile(null)
      alert('Розмір файлу повинен бути не більше 5 МБ')
    } else {
      // if file size is correct, check file type
      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/rtf',
        'text/markdown',
        'text/html',
      ]
      const fileType = selectedFile.type

      if (allowedTypes.includes(fileType)) {
        // if everything is okay set file in state
        setFile(selectedFile)
      } else {
        alert('Будь ласка, завантажте файл у дозволеному форматі')
      }
    }
  }

  const [numberQuestion, setNumberQuestion] = useState(1) // State to track question number
  let disabledButton = {
    background: 'linear-gradient(94deg, #E1F4FE 11.04%, #CFE8FF 100.02%)',
    color: 'black ',
  }

  let onSubmit = async (obj) => {
    let { name, date, email, phone, work, place } = obj

    let msg = `
Тема: 'Вакансії для тренерів'
Ім'я - ${name}
Дата - ${date}
E-mail - ${email}
Номер телефону - ${phone}
Досвід роботи - ${work}
Місця роботи - ${place}
`

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        validator: validator,
        chat_id: chatId,
        message: msg,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        const CV = new FormData()
        CV.append('file', file)

        fetch(fileURL, {
          method: 'POST',
          body: CV,
        })
          .then((response) => response.json())
          .then((data) => {})
          .catch((error) => {
            alert('Спробуйте ще раз')
          })
      })
      .catch((error) => {
        console.error('Помилка при виконанні запиту:', error)
      })

    setNumberQuestion(2)
  }

  return (
    <div>
      <motion.div
        variants={variants}
        transition={{ duration: 1 }}
        animate={numberQuestion === 1 ? 'visible' : 'unVisible'}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
          <input
            type="text"
            required
            className={`input ${formData.name ? 'filled' : ''} ${
              errors.name ? 'error' : ''
            }`}
            {...register('name', { required: true })}
            placeholder={'Введіть ваше ПІБ'}
            style={errors.name ? redBord : blackBord}
          />
          <input
            type="date"
            placeholder="Дата народження"
            required
            className={`input ${formData.date ? 'filled' : ''} ${
              errors.date ? 'error' : ''
            }`}
            {...register('date', { required: true })}
            style={errors.date ? redBord : blackBord}
          />

          <input
            type="text"
            required
            className={`input ${formData.email ? 'filled' : ''} ${
              errors.email ? 'error' : ''
            }`}
            {...register('email', { required: true })}
            placeholder={'E-mail'}
            style={errors.email ? redBord : blackBord}
          />
          <input
            type="text"
            required
            className={`input ${formData.phone ? 'filled' : ''} ${
              errors.phone ? 'error' : ''
            }`}
            {...register('phone', { required: true })}
            placeholder={'Номер телефону'}
            style={errors.phone ? redBord : blackBord}
          />

          <input
            type="text"
            required
            className={`input ${formData.work ? 'filled' : ''} ${
              errors.work ? 'error' : ''
            }`}
            {...register('work', { required: true })}
            placeholder={'Досвід роботи'}
            style={errors.work ? redBord : blackBord}
          />
          <input
            type="text"
            required
            className={`input ${formData.place ? 'filled' : ''} ${
              errors.place ? 'error' : ''
            }`}
            {...register('place', { required: true })}
            placeholder={'Місця роботи'}
            style={errors.place ? redBord : blackBord}
          />
          <label className="formTeacher-file">
            <input
              type="file"
              className={`input ${formData.file ? 'filled' : ''} ${
                errors.file ? 'error' : ''
              }`}
              {...register('file', { required: false })}
              style={errors.file ? redBord : blackBord}
              onChange={handleFileChange}
            />{' '}
            Додати резуме
            <img
              src={TeacherFileImg}
              alt=""
              className="formTeacher-file__img"
            />
          </label>

          <button
            type="submit"
            className="send-form"
            style={isValid ? null : disabledButton}
            disabled={!isValid}
          >
            Залишити заявку
          </button>
        </form>
        <Button className={'button_close'} onClick={props.onHide}>
          Закрити
        </Button>
      </motion.div>
      <motion.div
        variants={variants}
        transition={{ duration: 1 }}
        animate={numberQuestion === 2 ? 'visible' : 'unVisible'}
      >
        <div className={'futureTeachers-thanks'}>
          <h3 className={'yearlytrips-text  futureTeachers-thanks__title'}>
            {' '}
            Дякуємо за Вашу заявку!
          </h3>
          <button
            className={'header-block__button futureTeachers-thanks__button'}
            onClick={() => setNumberQuestion(1)}
          >
            {' '}
            Відправити повторно
          </button>
        </div>
      </motion.div>
    </div>
  )
}

export default FormTeachers
