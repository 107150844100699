import React from 'react'
import styles from './FooterTop.module.css'
import { inst_logo, logo } from '../../Assets/Images/MyImages/MyImages'
import { Link } from 'react-scroll'

const FooterTop = () => {
  return (
    <div className={`${styles.wrap} container`}>
      <img src={logo} alt={logo} className={styles.wrap_img} />
      <Link to="trainer" offset={-50} spy={true} className={styles.wrap_link}>
        Про тренера
      </Link>
      <Link to="schoolLink" offset={0} spy={true} className={styles.wrap_link}>
        Про школу
      </Link>
      <Link
        to="pediatrLink"
        offset={-20}
        spy={true}
        className={styles.wrap_link}
      >
        Педіатри
      </Link>

      <a href="tel:+380953240708" className={styles.wrap_link__tel}>
        +380 (95) 32 40 708
      </a>
      <a
        href="https://www.instagram.com/zdorova.dytuna/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
        className={styles.wrap_link__inst}
      >
        <img src={inst_logo} alt={inst_logo} />
      </a>
    </div>
  )
}

export default FooterTop
