import React from 'react'
import FooterTop from '../../Components/FooterTopBlock/FooterTop'
import CreatedBy from '../../Components/FooterCreatedBy/CreatedBy'

const Footer = () => {
  return (
    <div>
      <FooterTop />
      <CreatedBy />
    </div>
  )
}

export default Footer
