import React from 'react'
import styles from './AbTrainer.module.css'
import { dr3, dr4, trainer } from '../../Assets/Images/MyImages/MyImages'
const AbTrainer = () => {
  return (
    <div className={styles.box} id="trainer">
      <div className={`${styles.wrap} container`}>
        <img src={dr3} alt={dr3} className={styles.wrap_img1} />
        <img src={dr4} alt={dr4} className={styles.wrap_img2} />
        <div className={styles.wrap_left}>
          <h2 className={styles.wrap_left__title}>Про тренера</h2>
          <p className={styles.wrap_left__description}>
            Чедрик Тетяна Петрівна тренер міжнародного класу школи SSI.
            Тренерський безперервний стаж 8 років. За сумісництвом дитячий
            реабілітолог/масажист. <br /> <br /> Щорічне підвищення квалфікації
            у кращих тренерів та обмін досвідом. Співпраця з провідними школами
            України.
          </p>
        </div>
        <img src={trainer} alt={trainer} className={styles.wrap_right} />
      </div>
    </div>
  )
}

export default AbTrainer
