import React from 'react'
import './Instructor.css'
import { Curs_drop } from '../../Assets/Images/images'
import { Curs_drop2 } from '../../Assets/Images/images'
import { Curs_drop3 } from '../../Assets/Images/images'
import InstructorPopUp from './PopUp/InstructorPopUp'

const Instructor = () => {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <div className={'instructor_bg_color'}>
      <img className={'curs_drop_img'} src={Curs_drop} alt="Curs_drop" />
      <img className={'curs_drop_img2'} src={Curs_drop2} alt="Curs_drop" />
      <img className={'curs_drop_img3'} src={Curs_drop3} alt="Curs_drop" />
      <div className={'container'}>
        <div>
          <h2 className={'instructor_title'}>
            Курс «інструктор з <br /> малючкового плавання»
          </h2>
          <p className={'instructor_sub_title'}>Для кого підійде це навчання</p>
        </div>
        <div className={'row'}>
          <div className={'col-lg-4 col-md-4 col-12'}>
            <div className={'curs_block'}>
              <h1 className={'curs_number'}>01</h1>
              <p className={'curs_text'}>
                практикуючих <br /> інструкторів
              </p>
            </div>
          </div>
          <div className={'col-lg-4 col-md-4 col-12'}>
            <div className={'curs_block'}>
              <h1 className={'curs_number'}>02</h1>
              <p className={'curs_text'}>мам в декреті</p>
            </div>
          </div>
          <div className={'col-lg-4 col-md-4 col-12'}>
            <div className={'curs_block'}>
              <h1 className={'curs_number'}>03</h1>
              <p className={'curs_text'}>
                медичних <br /> працівників
              </p>
            </div>
          </div>
          <div className={'col-lg-4 col-md-4 col-12'}>
            <div className={'curs_block'}>
              <h1 className={'curs_number'}>04</h1>
              <p className={'curs_text'}>
                професійних <br /> плавців
              </p>
            </div>
          </div>
          <div className={'col-lg-4 col-md-4 col-12'}>
            <div className={'curs_block'}>
              <h1 className={'curs_number'}>05</h1>
              <p className={'curs_text'}>
                студентів <br /> реабілітологів
              </p>
            </div>
          </div>
        </div>
        <div className={'curs_button'}>
          <button
            className={'instructor_button'}
            onClick={() => setModalShow(true)}
          >
            Залишити заявку
          </button>
          <InstructorPopUp
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
    </div>
  )
}
export default Instructor
