import './Accordion.css'
import { AnimatePresence,motion } from 'framer-motion'

const AccordionSection = ({
                            section,
                            isActiveSection,
                            setActiveIndex,
                            sectionIndex,
                          }) => {
  const toggleSection = () => {
    const nextIndex = isActiveSection ? null : sectionIndex
    setActiveIndex(nextIndex)
  }
  return (
    <div className="container accordion__container"  >
      <motion.div
        className={'accordion-wrapper'}
        onClick={toggleSection}
        animate={isActiveSection ? {height: 'auto', background: "var(--4, #FDFCFC)"} : {height: "auto", background: "var(--4, #FDFCFC)"}}
        exit={{height: 'auto'}}

      ><AnimatePresence>
        <div className='accordion-label'>
          <h4 className="accordion-title">{section.title}</h4>
          <div className="accordion-symbol">{isActiveSection ? '-' : '+'}</div>
        </div>
      </AnimatePresence>
          <AnimatePresence>
            {isActiveSection &&
              <motion.p
                transition={{duration: 0.2}}
                initial={{height: 0}}
                animate={{height: "auto"}}
                exit={{height: 0}}
                className="accordion-content"><motion.p
                transition={{duration: 0.5}}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
                >{section.content}</motion.p></motion.p>}
          </AnimatePresence>
      </motion.div>
    </div>
  )
}
export default AccordionSection
