import React from 'react'
import styles from './AbSchool.module.css'
import { dr1, dr2 } from '../../Assets/Images/MyImages/MyImages'
import MyCarousele from './Carousel/MyCarousele'

const AbSchool = () => {
  return (
    <div className={styles.box} id="schoolLink">
      <div className={`${styles.wrap} container`}>
        <img src={dr1} alt={dr1} className={styles.dr1} />
        <img src={dr2} alt={dr2} className={styles.dr2} />
        <h2 className={styles.wrap_title}>Про школу</h2>
        <p className={styles.wrap_description}>
          Школа раннього плавання «Здорова дитина» заснована та працює <br /> з
          2015 року. <br />
          <br /> За період існування школа навчила плавати більш ніж 500 діток.
        </p>

        <div className={styles.wrap_carousele} id={'asd'}>
          <MyCarousele />
        </div>
      </div>
    </div>
  )
}

export default AbSchool
