import { useState } from 'react'
import './FAQ.css'
import AccordionSection from '../../Components/Accordion/Accordion'



const FAQ = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState()
  return (
    <div className="faq">
      <div className='container faq-wrapper'>
        <div className='faq-raindropTwo'></div>
        <div className='faq-raindropThree'></div>
        <div className='faq-title'>Відповіді на часті запитання<div className='faq-raindropOne'></div></div>
      {sections.map((section, index) => (
        <AccordionSection
          section={section}
          key={index}
          isActiveSection={index === activeIndex}
          setActiveIndex={setActiveIndex}
          sectionIndex={index}
        />
      ))}
      </div>
    </div>
  )
}

export default FAQ
