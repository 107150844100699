import React from 'react'
import styles from './Students.module.css'

const Student = ({ name, year, about, image }) => {
  return (
    <div className={styles.students}>
      <img src={image} className={styles.students_img} alt="student_img" />
      <div className={styles.student_info}>
        <h2 className={styles.students_name}>{name}</h2>
        <p className={styles.student_year}> {year}</p>
        <p className={styles.student_about}>{about}</p>
      </div>
    </div>
  )
}

export default Student
