import React from 'react'
import styles from './CreatedBy.module.css'

const CreatedBy = () => {
  return (
    <div className={styles.wrap}>
      <h2 className={styles.wrap_title}>Сайт розроблений </h2>
      <a href="https://www.znaesh.agency/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="232"
          height="79"
          viewBox="0 0 232 79"
          fill="none"
          className={styles.logo}
        >
          <path
            d="M83.4848 26.3789H88.3556V34.0236H102.218V26.3789H107.089V53.1358H102.218V38.6115H88.3556V53.1358H83.4848V26.3789Z"
            fill="white"
          />
          <path
            d="M118.696 26.3789H129.933L138.925 53.1358H133.869L131.059 44.7255L116.826 47.2108L114.766 53.1358H109.71L118.696 26.3789ZM129.558 40.5253L126.373 30.9668H122.252L118.316 42.4341L129.558 40.5253Z"
            fill="white"
          />
          <path
            d="M138.551 39.759C138.551 32.4967 143.607 25.6123 152.973 25.6123C161.965 25.6123 165.335 31.5373 166.271 35.5537L161.4 36.1252C160.65 33.6399 158.589 30.2002 152.973 30.2002C148.552 30.2002 145.182 32.7979 143.947 36.5081H154.849V41.096H143.497C144.057 45.7961 147.652 49.3175 152.973 49.3175C157.654 49.3175 160.465 46.6434 161.59 45.3013L165.71 47.9754C164.585 49.3124 161.215 53.9003 152.973 53.9003C143.607 53.9003 138.551 47.021 138.551 39.759Z"
            fill="white"
          />
          <path
            d="M168.891 26.3789H173.762V48.5529H185.374V26.3789H190.245V48.5529H201.857V26.3789H206.727V53.1358H168.891V26.3789Z"
            fill="white"
          />
          <path
            d="M121.892 69.9082H125.587L128.543 78.7064H126.878L125.952 75.9403L121.272 76.7568L120.592 78.7064H118.926L121.892 69.9082ZM125.462 74.5624L124.417 71.4187H123.062L121.767 75.1901L125.462 74.5624Z"
            fill="white"
          />
          <path
            d="M139.886 74.3074C139.886 71.8579 141.551 69.6582 144.627 69.6582C147.152 69.6582 148.692 71.1689 148.997 72.4856L147.397 72.6744C147.152 71.8579 145.982 71.1639 144.627 71.1639C142.776 71.1639 141.486 72.5468 141.486 74.3074C141.486 76.0682 142.781 77.4511 144.627 77.4511C146.472 77.4511 147.522 76.3845 147.522 76.3845V75.3792H145.367V73.935H149.002V78.7118H147.522V78.0841C147.522 78.0841 146.412 78.9618 144.627 78.9618C141.551 78.9618 139.886 76.7622 139.886 74.3074Z"
            fill="white"
          />
          <path
            d="M161.705 69.9082H169.096V71.4187H163.305V72.8631H167.676V74.3736H163.305V77.201H169.096V78.7115H161.705V69.9082Z"
            fill="white"
          />
          <path
            d="M181.791 69.9082H183.088L188.077 75.8791V69.9082H189.679V78.7064H188.382L183.393 72.7356V78.7064H181.791V69.9082Z"
            fill="white"
          />
          <path
            d="M202.252 74.3074C202.252 71.9191 203.919 69.6582 206.994 69.6582C209.95 69.6582 211.059 71.6078 211.365 72.9244L209.765 73.1133C209.52 72.2968 208.839 71.1639 206.994 71.1639C205.148 71.1639 203.854 72.5468 203.854 74.3074C203.854 76.0682 205.083 77.4511 206.994 77.4511C208.533 77.4511 209.459 76.5733 209.83 76.1294L211.184 77.0072C210.814 77.4461 209.705 78.9565 206.998 78.9565C203.919 78.9618 202.252 76.6959 202.252 74.3074Z"
            fill="white"
          />
          <path
            d="M226.472 75.5075L222.717 69.9141H224.567L227.276 73.9355L229.987 69.9141H231.837L228.082 75.5075V78.7123H226.482V75.5075H226.472Z"
            fill="white"
          />
          <path
            d="M102.468 78.7325H0.725342V0.757812H231.832V53.1236H230.327V2.29401H2.23059V77.1963H102.468V78.7325Z"
            fill="white"
          />
          <path
            d="M75.2758 29.5377C69.5363 15.974 53.8725 9.62893 40.2936 15.3598C26.7098 21.0909 20.3553 36.7315 26.0949 50.2906C31.8344 63.8542 47.498 70.1993 61.0768 64.4685C74.6607 58.7374 81.0151 43.1016 75.2758 29.5377ZM39.5089 24.301L61.067 22.993L63.6418 26.183L47.4331 39.3924L47.0381 32.8626L51.9327 28.689L39.8188 29.4229L39.5089 24.301ZM51.2779 58.1832C41.4587 58.7772 36.3591 51.3338 34.9241 49.8512L39.6439 46.4315C41.0786 47.9091 44.0035 53.0661 50.9529 52.8316C57.2123 52.6219 59.8971 47.8891 59.6921 44.5443C59.6021 43.0367 58.692 40.6953 55.4025 39.7969L59.797 36.2073C64.2117 38.1543 65.3715 42.1981 65.5065 44.4146C65.8315 49.7712 62.2119 57.5192 51.2779 58.1832Z"
            fill="white"
          />
        </svg>
      </a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="191"
        height="144"
        viewBox="0 0 191 163"
        fill="none"
        className={`${styles.wrap_backImg} ${styles.wrap_backImg__first}`}
      >
        <path
          opacity="0.87"
          d="M189.547 107.245C195.98 55.3279 159.065 7.99073 107.117 1.50366C55.1534 -4.99164 7.82881 31.8351 1.40343 83.7378C-5.03005 135.655 31.8841 182.991 83.8321 189.478C135.796 195.974 183.112 159.162 189.547 107.245ZM88.824 27.6139L157.462 61.8775L159.717 76.2634L86.3973 88.1102L96.7791 67.3222L119.248 63.1767L80.6806 43.9198L88.824 27.6139ZM64.852 152.741C33.5921 137.13 31.1245 105.176 29.3434 98.0669L49.9354 95.9297C51.7247 103.023 51.5631 124.081 73.3566 135.702C92.9838 146.174 109.648 136.383 114.958 125.73C117.358 120.933 118.717 112.114 110.194 103.508L130.087 100.271C140.209 114.101 136.595 128.6 133.074 135.657C124.56 152.712 99.6654 170.117 64.852 152.741Z"
          fill="white"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="125"
        height="84"
        viewBox="0 0 125 105"
        fill="none"
        className={`${styles.wrap_backImg} ${styles.wrap_backImg__second}`}
      >
        <g opacity="0.65" filter="url(#filter0_f_189_703)">
          <path
            d="M114.933 47.5135C106.907 18.4401 76.8018 1.36853 47.7 9.3725C18.5879 17.3756 1.50351 47.4313 9.53036 76.4954C17.556 105.569 47.661 122.64 76.7628 114.636C105.875 106.633 122.958 76.5873 114.933 47.5135ZM43.7155 27.3159L87.8348 30.4257L92.2094 37.5912L55.768 60.0754L56.7123 46.7121L67.7662 39.5476L42.9748 37.7978L43.7155 27.3159ZM58.5488 99.2547C38.454 97.8348 30.0909 81.3586 27.5739 77.9646L38.071 72.2838C40.5889 75.6674 45.1481 86.9199 59.3201 88.3024C72.0848 89.5506 78.8014 80.6597 79.2798 73.8139C79.5003 70.729 78.2787 65.7318 71.8402 63.028L81.7224 56.9153C90.1649 62.0489 91.4382 70.5693 91.1195 75.1055C90.3468 86.068 80.9257 100.831 58.5488 99.2547Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_189_703"
            x="0.231833"
            y="0.0822964"
            width="123.999"
            height="123.844"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="3.65856"
              result="effect1_foregroundBlur_189_703"
            />
          </filter>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="139"
        height="136"
        viewBox="0 0 139 136"
        fill="none"
        className={`${styles.wrap_backImg} ${styles.wrap_backImg__third}`}
      >
        <g filter="url(#filter0_f_189_701)">
          <path
            d="M115.929 26.4044C93.4881 0.894217 54.5804 -1.58795 29.0307 20.845C3.47021 43.2821 0.950649 82.1457 23.3882 107.646C45.8291 133.156 84.7363 135.638 110.286 113.205C135.846 90.7683 138.369 51.9153 115.929 26.4044ZM33.8242 40.988L80.0607 22.4257L88.02 27.5335L62.1596 68.2514L56.5397 54.2458L64.2145 41.5457L38.2324 51.9738L33.8242 40.988ZM84.2596 106.583C63.1988 115.034 46.6156 102.455 42.3948 100.254L50.2356 89.3318C54.4523 91.5213 64.6101 100.68 79.651 95.1057C93.1997 90.0884 95.63 77.7736 92.7456 70.6013C91.4507 67.3668 87.7536 62.9044 79.8987 63.3334L86.9039 52.2759C97.9853 53.3227 103.469 61.3298 105.378 66.0832C109.991 77.5718 107.71 97.1675 84.2596 106.583Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_189_701"
            x="0.763083"
            y="-1.79759"
            width="137.792"
            height="137.645"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="3.65856"
              result="effect1_foregroundBlur_189_701"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export default CreatedBy
