import React from 'react'
import ButtonSignUp from '../ButtonSignUp/ButtonSignUp'
import { headerLogo } from '../../Assets/Icons/icons'

const HeaderBlock = () => {
  return (
    <div className="header-block container">
      <img src={headerLogo} alt="" className="header-logo_mobile" />
      <h1 className="header-block__title">"Здорова дитина"</h1>
      <p className="header-block__text">Плавати швидше ніж ходити</p>
      <ButtonSignUp
        buttonClass="header-button header-block__button"
        ButtonText={'Записатись'}
        buttonLink={'#formLink'}
        buttonStyle={'header-block__link'}
      />
    </div>
  )
}

export default HeaderBlock
