import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import FormTeachers from '../../Components/FormTeachers/FormTeachers'
import './FutureTeachers.css'
const FutureTeachers = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div className='futureTeachers'>
      <div className='futureTeachers-wrapper container'>
      <h2 className='futureTeachers-title'>Вакансії для тренерів</h2>
      <p className='futureTeachers-text'>Запрошуємо в нашу команду практикуючих інструкторів або тих хто хоче ними статити</p>
      <button onClick={handleShow} className='futureTeachers-button'>Залишити заявку</button>
      <Modal
             aria-labelledby="contained-modal-title-vcenter"
             centered show={show} onHide={handleClose} id="popup">
        <div className='modalTeacher'>
        <h2 className='teachersPopup-title'>Вакансії для тренерів</h2>
      <FormTeachers show={handleShow}
                    onHide={() => handleClose(false)} />
        </div>
      </Modal>
      </div>
    </div>
  )
}

export default FutureTeachers
