import React, { useContext } from 'react'
import styles from './Group.module.css'
import { Link } from 'react-scroll'
import MyContext from '../../../MyContext'

const Group = ({ img, title, desc, index }) => {
  const { setValueOfGroup, setShowGroupPopUp, showGroupPopUp, setNumberGroup } =
    useContext(MyContext)

  const addValue = () => {
    setValueOfGroup(title)
  }

  return (
    <div className={`${styles.box}`}>
      <img src={img} alt={img} className={styles.box_img} />
      <h3
        className={`${styles.box_title} ${
          index === 5
            ? styles.box_title6
            : index === 4
            ? styles.box_title5
            : index === 3
            ? styles.box_title4
            : null
        }`}
      >
        {title}
      </h3>
      <p className={styles.box_description}>{desc}</p>
      <div className={styles.box_btnBox}>
        <button
          className={styles.box_btnBox__first}
          onClick={() => {
            setShowGroupPopUp(!showGroupPopUp)
            setNumberGroup(index + 1)
          }}
        >
          Детальніше
        </button>

        <Link
          to="form"
          spy={true}
          offset={-480}
          className={styles.box_btnBox__second_link}
        >
          <button className={styles.box_btnBox__second} onClick={addValue}>
            Записатись
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Group
