import React from 'react'
import { PrevArrow } from '../../../Assets/Icons/icons'

export default function SampleNextArrowTabletMobile({
  className,
  style,
  onClick,
}) {
  return (
    <img
      src={PrevArrow}
      alt="arrowNext"
      onClick={onClick}
      className={className}
      style={{
        top: '115%',
        left: '30%',
        width: '41px',
        height: '41px',
        zIndex: '2',
      }}
    />
  )
}
