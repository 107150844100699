import React from 'react'
import "./Header.css"
import HeaderNavBar from '../../Components/HeaderNavBar/HeaderNavBar'
import HeaderBlock from '../../Components/HeaderBlock/HeaderBlock'
import VideoBackground from '../../Components/VideoBackground/VideoBackground'
const Header = () => {
  return (
    <div>
    <HeaderNavBar/>
    <HeaderBlock/>
    <VideoBackground/>

    </div>

  )
}

export default Header
