import React from 'react'

import { arrowNext } from '../../../Assets/Icons/icons'

export default function StudentsNextArrow({ className, style, onClick }) {
  return (
    <img
      src={arrowNext}
      alt="arrowNext"
      onClick={onClick}
      className={className}
      style={{ top: '115%', right: '42%', width: '53px', height: '53px' }}
    />
  )
}
