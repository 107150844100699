import React from 'react'
import { HeaderBG } from '../../Assets/Images/images'

const VideoBackground = () => {
  return (
    <div className="video-background">
      <video
        src={HeaderBG}
        autoPlay
        muted
        playsInline
        loop
        id="background-video"
      ></video>
    </div>
  )
}

export default VideoBackground
