import React, { useContext, useEffect, useState } from 'react'
import styles from './Button.module.css'
import MyContext from '../../../MyContext'

const Button = ({ content, index }) => {
  const { valueOfGroup, setValueOfGroup } = useContext(MyContext)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const isTrue =
      valueOfGroup?.toString().slice(0, 5) === content.toString().slice(0, 5)
    setIsActive(isTrue)
  }, [valueOfGroup, content])

  const addStyle = () => {
    setIsActive(false)
    setIsActive(true)
    setValueOfGroup(content)
  }

  return (
    <button
      onClick={addStyle}
      className={`${styles.btn} ${index === 5 ? styles.btn6 : null} ${
        isActive ? styles.btn_active : null
      } ${isActive && index === 5 ? styles.btn_active6 : null}`}
    >
      {content}
    </button>
  )
}

export default Button
