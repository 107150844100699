import React from 'react'
import { PrevArrow } from '../../../Assets/Icons/icons'

export default function SampleNextArrow({ className, style, onClick }) {
  return (
    <img
      src={PrevArrow}
      alt="arrowNext"
      onClick={onClick}
      className={className}
      style={{
        top: '110%',
        left: '42%',
        width: '53px',
        height: '53px',
        zIndex: '2',
      }}
    />
  )
}
