import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import './InstructorPopUp.css'
import { Curs_drop } from '../../../Assets/Images/images'
import { baseURL, chatId, validator } from '../../../Constants/urls.js'

const InstructorPopUp = (props) => {
  const {
    register,
    handleSubmit,
    reset, // eslint-disable-line
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  let redBord = { border: '2px solid red' }
  let whiteBord = { border: '2.438px solid #1A92FD' }

  const onSubmit = async (data) => {
    let name = data.name
    let phone = data.phone
    let msg = `
    Заявка на курс з малючкового плавання: 
    Ім'я - ${name} 
    Телефон - ${phone} 
    `

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        validator: validator,
        chat_id: chatId,
        message: msg,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        console.log('Відповідь сервера:', data)
      })
      .catch((error) => {
        console.error('Помилка при виконанні запиту:', error)
      })

    reset()
  }

  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className={'popup_bg '}>
            <img
              className={'curs_popup_drop_img'}
              src={Curs_drop}
              alt="Curs_drop"
            />
            <div className={'container '}>
              <h2 className={'instructor_title_popup'}>
                Курс «інструктор з <br /> малючкового плавання»
              </h2>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={'box_popup_form'}
                id="curs_form"
              >
                <input
                  {...register('name', {
                    required: true,
                    pattern: new RegExp(/^[a-zA-ZА-яіІїЇҐґєЄ]{3,25}$/),
                  })}
                  type="text"
                  className={'box_popup_form__inp'}
                  placeholder={'Ім’я та прізвище'}
                  style={errors.name ? redBord : whiteBord}
                  autoComplete="off"
                />
                <input
                  {...register('phone', {
                    required: true,
                    pattern: new RegExp(/^\+380\d{9}$/),
                  })}
                  name="phone"
                  defaultValue={'+380'}
                  type="text"
                  className={'box_popup_form__inp'}
                  style={errors.phone ? redBord : whiteBord}
                  autoComplete="off"
                />
                <button
                  type="submit"
                  className={'box_popup_form__btn'}
                  disabled={!errors}
                >
                  Залишити заявку
                </button>
              </form>
              <Button className={'button_close'} onClick={props.onHide}>
                Закрити
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default InstructorPopUp
