import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styles from './Students.module.css'
import {
  Adam,
  Gulianich_Kiril,
  Gulianich_Lubomir,
  Polina,
  Priima_Sofia,
  Roman,
} from '../../Assets/Images/images'
import StudentsNextArrow from './Students_Arrows/StudentsNextArrow'
import StudentsNextArrowTablet from './Students_Arrows/StudentsNextArrowTablet'
import StudentsPrevArrow from './Students_Arrows/StudentsPrevArrow'
import StudentsPrevArrowTablet from './Students_Arrows/StudentsPrevArrowTablet'
import Student from './Student'
import StudentsNextArrowMobile from './Students_Arrows/StudentsNextArrowMobile'
import StudentsPrevArrowMobile from './Students_Arrows/StudentsPrevArrowMobile'

const students = [
  {
    id: 1,
    name: 'Гулянич Кирил',
    year: '4 рочки',
    image: Gulianich_Kiril,
    about: 'Займається плаванням з 2,5 років',
  },

  {
    id: 2,
    name: 'Гулянич Любомир',
    year: '3 рочки',
    image: Gulianich_Lubomir,
    about: 'Займається плаванням з 6 місяців',
  },

  {
    id: 3,
    name: 'Прийма Софія',
    year: '4 рочки',
    image: Priima_Sofia,
    about: 'Займається плаванням з 1,4 років',
  },
  {
    id: 4,
    name: 'Веждел Полінка',
    year: '3 рочки',
    image: Polina,
    about: 'Займається плаванням з 4 місяців',
  },

  {
    id: 5,
    name: 'Філіп Роман',
    year: '3 рочки',
    image: Roman,
    about: 'Займається плаванням з 1 рочку 2 місяців',
  },
  {
    id: 6,
    name: 'Лацканич Адам',
    year: '4 рочки',
    image: Adam,
    about: 'Займається плаванням з 1 рочку',
  },
]

const Students = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <StudentsNextArrow />,
    prevArrow: <StudentsPrevArrow />,
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          nextArrow: <StudentsNextArrowTablet />,
          prevArrow: <StudentsPrevArrowTablet />,
        },
      },
      {
        breakpoint: 720,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <StudentsNextArrowMobile />,
          prevArrow: <StudentsPrevArrowMobile />,
        },
      },
    ],
  }
  return (
    <div className={styles.students_bg}>
      <div className={'container'}>
        <div>
          <h1 className={styles.students_title}>
            Найменші, що займаються вже самостійно
          </h1>
        </div>
        <div className={styles.students_block}>
          <div className={styles.students_block_slider}>
            <Slider {...settings}>
              {students.map((student) => (
                <Student
                  image={student.image}
                  name={student.name}
                  key={student.id}
                  year={student.year}
                  about={student.about}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Students
