import React from 'react'
import './ImageModal.css'

function ImageModal({ imageUrl, onClose }) {
  return (
    <div className="image-modal" onClick={onClose}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <svg className='close-button'
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
            >
              <rect
                x="3.53564"
                width="48"
                height="5"
                transform="rotate(45 3.53564 0)"
                fill="#1EC5EB"
              />
              <rect
                y="33.9411"
                width="48"
                height="5"
                transform="rotate(-45 0 33.9411)"
                fill="#1EC5EB"
              />
            </svg>
        </button>
        <img src={imageUrl} alt="" />
      </div>
    </div>
  )
}

export default ImageModal
