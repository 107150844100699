import React, { useContext } from 'react'
import styles from './Form.module.css'
import Button from './Buttons/Button'
import { useForm } from 'react-hook-form'
import MyContext from '../../MyContext'
import { baseURL, chatId, validator } from '../../Constants/urls.js'

const Form = () => {
  const { valueOfGroup } = useContext(MyContext)
  const btns = [
    {
      content: '2-10 місяців',
    },
    {
      content: '1-1,5 роки',
    },
    {
      content: '1,5-3 роки',
    },
    {
      content: '3-6 років',
    },
    {
      content: '6-12 років',
    },
    {
      content: 'Діти з особливими потребами',
    },
  ]

  const {
    register,
    handleSubmit,
    reset, // eslint-disable-line
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  let redBord = { border: '2px solid red' }
  let whiteBord = { border: '2.438px solid #1A92FD' }

  const onSubmit = async (data) => {
    let name = data.name
    let phone = data.phone
    let msg = `Ім'я - ${name} 
    Телефон - ${phone} 
    Група - ${valueOfGroup}`

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        validator: validator,
        chat_id: chatId,
        message: msg,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        console.log('Відповідь сервера:', data)
      })
      .catch((error) => {
        console.error('Помилка при виконанні запиту:', error)
      })

    reset()
  }

  return (
    <div className={styles.wrap} id="formLink">
      <div className={`${styles.box} container`}>
        <h2 className={styles.box_title}>Зв’язок з нами</h2>
        <p className={styles.box_desc}>
          Обирайте групу, залишайте заявку і наш менеджер зв’яжеться з вами
          протягом 15 хвилин в робочий час!
        </p>
        <div className={styles.box_btnsBox}>
          {btns.map((value, index) => {
            return <Button content={value.content} key={index} index={index} />
          })}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.box_form}
          id="form"
        >
          <input
            {...register('name', {
              required: true,
              pattern: new RegExp(/^[a-zA-ZА-яіІїЇҐґєЄ]{3,25}$/),
            })}
            type="text"
            className={styles.box_form__inp}
            placeholder={'Ім’я та прізвище'}
            style={errors.name ? redBord : whiteBord}
            autoComplete="off"
          />
          <input
            {...register('phone', {
              required: true,
              pattern: new RegExp(/^\+380\d{9}$/),
            })}
            name="phone"
            defaultValue={'+380'}
            type="text"
            className={styles.box_form__inp}
            style={errors.phone ? redBord : whiteBord}
            autoComplete="off"
          />
          <button
            type="submit"
            className={styles.box_form__btn}
            disabled={!errors}
          >
            {' '}
            Надіслати
          </button>
        </form>
      </div>
    </div>
  )
}

export default Form
