import React, { useContext, useEffect, useState } from 'react'
import styles from './Groups.module.css'
import {
  groupPhoto6,
  groupBack,
  groupPhoto1,
  groupPhoto2,
  groupPhoto3,
  groupPhoto4,
  groupPhoto5,
  GroupMobileBg,
} from '../../Assets/Images/MyImages/MyImages'
import Group from './Group/Group'
import MyContext from '../../MyContext'
import GroupPopUp from '../GroupPopUp/GroupPopUp'
import GroupPopUpFourandFive from '../GroupPopUp/GroupPopUpFourandFive'
import GroupPopUpSix from '../GroupPopUp/GroupPopUpSix'

const Groups = () => {
  const cards = [
    {
      img: groupPhoto1,
      title: '2-10 місяців',
      desc: 'Заняття в цій групі проводиться в присутності батьків!',
    },
    {
      img: groupPhoto2,
      title: '1-1,5 роки',
      desc: 'Заняття в цій групі проводиться в присутності батьків!',
    },
    {
      img: groupPhoto3,
      title: '1,5-3 роки',
      desc: 'Заняття в цій групі проводиться в присутності батьків!',
    },
    {
      img: groupPhoto4,
      title: '3-6 років самостійна',
      desc: 'Заняття в цій групі проводиться без батьків!',
    },
    {
      img: groupPhoto5,
      title: '6-12 років самостійна',
      desc: 'Заняття в цій групі проводиться без батьків!',
    },
    {
      img: groupPhoto6,
      title: 'Діти з особливими потребами',
      desc: 'Заняття в цій групі проводиться в присутності батьків!',
    },
  ]

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { showGroupPopUp, numberGroup } = useContext(MyContext)

  useEffect(() => {
    const contentWrapper = document.getElementById('firstModal') // Используйте ваш ID или селектор
    if (showGroupPopUp) {
      document.body.style.overflow = 'hidden'
    } else {
      contentWrapper.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showGroupPopUp])

  return (
    <div className={styles.box} id={'firstModal'}>
      {numberGroup === 1 ||
      numberGroup === 2 ||
      (numberGroup === 3 && showGroupPopUp) ? (
        <GroupPopUp />
      ) : null}
      {numberGroup === 4 || (numberGroup === 5 && showGroupPopUp) ? (
        <GroupPopUpFourandFive />
      ) : null}
      {numberGroup === 6 && showGroupPopUp ? <GroupPopUpSix /> : null}
      {windowWidth > 767 ? (
        <img src={groupBack} alt={groupBack} className={styles.box_photo} />
      ) : (
        <img
          src={GroupMobileBg}
          alt={GroupMobileBg}
          className={styles.box_photo}
        />
      )}

      <div className={'container'}>
        <div className={styles.wrap}>
          <h2 className={styles.wrap_title}>Групи</h2>
          <div className={styles.wrap_box}>
            {cards.map((card, index) => {
              return (
                <Group
                  key={index}
                  img={card.img}
                  title={card.title}
                  desc={card.desc}
                  index={index}
                />
              )
            })}
          </div>
        </div>
        <div className={styles.wrap_boxForNot}>
          <p className={styles.wrap_boxForNot_not}>
            *заняття відбуваються в присутності батьків до 6 років - далі
            самостійно
          </p>
          <p className={styles.wrap_boxForNot_not}>
            *якщо діти займаються з 2 місяців то з 3 років самостійно
          </p>
        </div>
      </div>
    </div>
  )
}

export default Groups
