import React, { useContext } from 'react'
import styles from './GroupPopUp.module.css'
import MyContext from '../../MyContext'
import {
  swimsuit_img,
  flippers_img,
  diaper_img,
  towel_img,
  cap_img,
  slippers_img,
} from '../../Assets/Images/MyImages/MyImages'

const GroupPopUp = () => {
  const { showGroupPopUp, setShowGroupPopUp } = useContext(MyContext)

  return (
    <div className={`${showGroupPopUp ? styles.wrap : styles.wrapN}`}>
      <div
        className={styles.backdrop}
        onClick={() => {
          setShowGroupPopUp(!showGroupPopUp)
        }}
      ></div>
      <div className={styles.backdrop_modal}>
        <div className={styles.backdrop_modal__close}>
          <svg
            className={styles.backdrop_modal__close_btn}
            onClick={() => {
              setShowGroupPopUp(!showGroupPopUp)
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
          >
            <rect
              y="25.1842"
              width="35.6159"
              height="5.39634"
              transform="rotate(-45 0 25.1842)"
              fill="#04549B"
            />
            <rect
              x="3.81592"
              y="0.00012207"
              width="35.6159"
              height="5.39634"
              transform="rotate(45 3.81592 0.00012207)"
              fill="#04549B"
            />
          </svg>
        </div>
        <p className={styles.backdrop_modal__alert}>
          Заняття в цій групі проводиться <span>в присутності батьків!</span>
        </p>
        <div className={styles.backdrop_modal__box}>
          <h2 className={styles.backdrop_modal__box__title}>
            Як підготуватись до заняття?
          </h2>
          <p className={styles.backdrop_modal__box__desc}>
            Щоб дитина не відчувала дискомфорту в басейні, оптимальна
            температура вдома під час купання має сягати позначки не вище 35
            градусів. Якщо ж вона вища за 35 градусів то необіхдно з кожним
            другим купанням зменшувати температуру води на пів градуса і з часом
            дійти до 34 градусів.
          </p>
        </div>
        <div className={styles.backdrop_modal__box}>
          <h2 className={styles.backdrop_modal__box__title}>
            Що потрібно мати дитині?
          </h2>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={diaper_img} alt={diaper_img} />
            <p className={styles.backdrop_modal__box__mini__text}>
              Памперс або трусики для басейну
            </p>
          </div>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={flippers_img} alt={flippers_img} />
            <p className={styles.backdrop_modal__box__mini__text}>Ласти</p>
          </div>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={slippers_img} alt={slippers_img} />
            <p className={styles.backdrop_modal__box__mini__text}>
              Змінне взуття
            </p>
          </div>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={towel_img} alt={towel_img} />
            <p className={styles.backdrop_modal__box__mini__text}>Рушник</p>
          </div>
        </div>
        <div className={styles.backdrop_modal__box}>
          <h2 className={styles.backdrop_modal__box__title}>
            Що потрібно мати батькам?
          </h2>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={swimsuit_img} alt={diaper_img} />
            <p className={styles.backdrop_modal__box__mini__text}>Купальник</p>
          </div>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={cap_img} alt={flippers_img} />
            <p className={styles.backdrop_modal__box__mini__text}>
              Шапочка для басейну{' '}
            </p>
          </div>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={slippers_img} alt={slippers_img} />
            <p className={styles.backdrop_modal__box__mini__text}>
              Змінне взуття
            </p>
          </div>
          <div className={styles.backdrop_modal__box__mini}>
            <img src={towel_img} alt={towel_img} />
            <p className={styles.backdrop_modal__box__mini__text}>Рушник</p>
          </div>
        </div>
        <p className={styles.backdrop_modal__alert}>
          <span>Обов'язково</span> для допуску до заняття потрібна{' '}
          <span>довідка від педіатра</span> з підтверженням, що немає
          протипоказань до плавання!
        </p>
      </div>
    </div>
  )
}

export default GroupPopUp
