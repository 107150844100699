import React from 'react'
import './YearlyTrips.css'
import YearlyTripsList from '../../Components/YearlyTripsList/YearlyTripsList'
import { Yearly1, Yearly2, Yearly3, Yearly4 } from '../../Assets/Images/images'
const YearlyTrips = () => {
  const tripsData = [
    {
      img: Yearly1,
      title: 'База відпочинку - "Червона Рута"',
      text: '2019 рік',
    },
    { img: Yearly2, title: 'База відпочинку - "LOGINDARIY"', text: '2020 рік' },
    { img: Yearly3, title: 'Готель “Сонячна Гора”', text: '2021 рік' },
    { img: Yearly4, title: 'OZY. team', text: '2022 рік' },
  ]
  return (
    <div className="yearlytrips ">
      <div className="container">
        <h2 className="yearlytrips-title ">Щорічні виїзди</h2>
        <p className="yearlytrips-text ">
          Щороку ми проводимо виїздні заняття на різноманітні локакації з
          дітками усіх вікових груп.
        </p>
        <div className="yearlytrips-wrapper ">
          <YearlyTripsList trips={tripsData} />
        </div>
      </div>
    </div>
  )
}

export default YearlyTrips
