import Doctors from './Modules/Doctors/Doctors'
import Header from './Modules/Header/Header'
import AbSchool from './Components/AboutSchool/AbSchool'
import AbTrainer from './Components/AboutTrener/AbTrener'
import Children from './Modules/Children/Children'
import FAQ from './Modules/FAQ/FAQ'
import { accordionData } from './Constants/Constants'
import Groups from './Components/Groups/Groups'
import Form from './Components/Form/Form'
import Reviews from './Modules/Reviews/Reviews'
import Cleaning from './Modules/Cleaning/Cleaning'
import Students from './Modules/Students/Students'
import Footer from './Modules/Footer/Footer'
import React from 'react'
import YearlyTrips from './Modules/YearlyTrips/YearlyTrips'
import Instructor from './Modules/Instructor/Instructor'
import FutureTeachers from './Modules/FutureTeachers/FutureTeachers'

function App() {
  return (
    <div className="App">
      <Header />
      <AbSchool />
      <AbTrainer />
      <Groups />
      <YearlyTrips />
      <Children />
      <Doctors />
      <Reviews />
      <Students />
      <Cleaning />
      <FAQ sections={accordionData} />
      <FutureTeachers />
      <Instructor />
      <Form />
      <Footer />
    </div>
  )
}

export default App
