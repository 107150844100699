import React from 'react'

import './Reviews.css'
const Review = ({ text, name, id, className }) => {
  return (
    <div className={`p-3 my_card ${className}`}>
      <h5 className={'testimonials_name'}>{name}</h5>
      <p className={'testimonials_text'}>{text}</p>
    </div>
  )
}
export default Review
