import React from 'react'
import './Cleaning.css'

const Cleaning = () => {
  return (
    <div className={'cleaning_bg'}>
      <div className={'container'}>
        <h1 className={'cleaning_heading'}>Чистота та комфорт</h1>
        <p className={'cleaning_text'}>
          Надсучасна фільтрація води, <br /> просторі роздлягальні, чистота та
          охайність.
        </p>
      </div>
    </div>
  )
}

export default Cleaning
