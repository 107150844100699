import React from 'react'

import { arrowNext } from '../../../Assets/Icons/icons'

export default function StudentsNextArrowTablet({ className, style, onClick }) {
  return (
    <img
      src={arrowNext}
      alt="arrowNext"
      onClick={onClick}
      className={className}
      style={{
        top: '100%',
        right: '40%',
        width: '41px',
        height: '41px',
        zIndex: '2',
      }}
    />
  )
}
