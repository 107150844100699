import React from 'react'
import Slider from 'react-slick'
import { useState } from 'react'

import './Reviews.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Review from './Review'
import ReviewNextArrow from './Reviews_Arrows/ReviewNextArrow'
import ReviewPrevArrow from './Reviews_Arrows/ReviewPrevArrow'
import { Review_drops, Review_drops_bg } from '../../Assets/Images/images'
import ReviewsNextArrowTablet from './Reviews_Arrows/ReviewsNextArrowTablet'
import ReviewsPrevArrowTablet from './Reviews_Arrows/ReviewsPrevArrowTablet'
import ReviewsNextArrowMobile from './Reviews_Arrows/ReviewsNextArrowMobile'
import ReviewsPrevArrowMobile from './Reviews_Arrows/ReviewsPrevArrowMobile'

const reviews = [
  {
    id: 1,
    text: 'Ви собі не уявляєте, яка я щаслива, що потрапила саме до вас. Я безмежно вам вдячна! Моя дитина така щаслива з вами. Хочеться плакати, коли думаю про те, що вдома цього не буде. Ви реально крута! Можливо ще доля зведе нас разом. Дякую ще раз!',
    name: 'Демська Тетяна',
  },
  {
    id: 2,
    text: ' Дякуємо, я чесно кажучи, в шоці, бо моя Кіра дуже боїться води вище поясу. А тут вже мене замучила питаннями, чого так довго треба чекати наступного заняття. Ви велика молодець, діти в захваті. дякуємо!!!',
    name: 'Талабіра Оксана',
  },
  {
    id: 3,
    text: 'Шановний тренер, дякуємо вам за допомогу та підтримку, чітко організовану роботу, мудрі поради і доброту! Ви найкраща у цій справі! Дуже щасливі, що саме ви почали навчати Софійку плавати.',
    name: 'Тамран Марія',
  },
  {
    id: 4,
    text: ' Таня, величезне тобі спасибі за те що робиш! Сьогодні наше розважальне тренування батьків та дітей було неймовірне, незабутнє, класне та дуже-дуже веселе. Прийшло розуміння того, що на тренуванні нашим діткам не просто. Але цим усім ти виховуєш в них витривалість, терпіння, певну конкуренцію та бажання досягати кращого та кращого.',
    name: 'Талабіра Оксана',
  },
  {
    id: 5,
    text: 'Дякую вам дуже! Син дуже любить тренування, ще жодного разу не сказав, що не хоче йти. Я дуже рада, що йому подобається, враховуючи, що він навіть вмиватись не любить, бо не любив ніколи воду.',
    name: 'Худякова Оксана',
  },
  {
    id: 6,
    text: 'Шикарний фахівець з дуже сильним педагогічним підходом. Тут можна сказати, що правильний розвиток дитини, починається з її рук!',
    name: 'Максимець Олександр',
  },
  {
    id: 7,
    text: 'Тренерів, які всю душу вкладають у роботу, зовсім небагато! Ви, Таня, цілком належите спорту і готові в потрібну хвилину допомогти, даруєте стимул нашим дітям ставати все краще! Спасибі Вам, дорогий тренер, спасибі за віру, і підтримку за силу і майстерність, за сміливість і наполегливість. Досягнення наших дітей - Ваші заслуги, завдяки Вам вони з кожним днем стають кращими.',
    name: 'Чабрун Галина',
  },
]

const Reviews = () => {
  const [slideindex, setSlideIndex] = useState(0)

  const settings = {
    infinite: true,
    slidesToShow: 3,
    arrows: true,
    speed: 500,
    nextArrow: <ReviewNextArrow />,
    prevArrow: <ReviewPrevArrow />,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          nextArrow: <ReviewsNextArrowTablet />,
          prevArrow: <ReviewsPrevArrowTablet />,
          beforeChange: (current, next) => setSlideIndex(next),
        },
      },
      {
        breakpoint: 720,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <ReviewsNextArrowMobile />,
          prevArrow: <ReviewsPrevArrowMobile />,
          beforeChange: (current, next) => setSlideIndex(next),
        },
      },
    ],
  }
  return (
    <div className={'reviews_bg_color'}>
      <img
        src={Review_drops}
        className={'review_drops_img'}
        alt="Review_drops"
      />
      <div className={'container'}>
        <div className={'reviews_title'}>Відгуки:</div>
        <Slider {...settings}>
          {reviews.map((revie, index) => (
            <Review
              className={
                (index || reviews.length) === slideindex + 1
                  ? 'slide slide-active'
                  : 'slide'
              }
              key={revie.id}
              name={revie.name}
              text={revie.text}
            />
          ))}
        </Slider>
      </div>
      <img
        src={Review_drops_bg}
        className={'review_drops_bg'}
        alt="Review_drops_bg"
      />
    </div>
  )
}
export default Reviews
